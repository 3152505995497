<script>
import { useRouter } from "vue-router";
import { ROUTE_LIST_NANNY_PATH } from "@/router/constants";
import authService from "@/services/authService";

export default {
  setup() {
    const router = useRouter();
    authService.logout();
    // TODO: check this out
    router.push(ROUTE_LIST_NANNY_PATH);
  },
  render() {
    return false;
  },
};
</script>
